import {
  Alert,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { t } from 'ttag';

import { useMessage } from '../useMessage';
import BackToSignIn from '../widgets/BackToSignIn';

interface ForgottenPasswordForm {
  username?: string;
}

export type ForgottenPasswordProps = RouteComponentProps<{}, {}, {
  username: string;
}>;

const ForgottenPassword: React.FC<ForgottenPasswordProps> = ({ location }) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm<ForgottenPasswordForm>({
    defaultValues: {
      username: location.state.username,
    },
  });

  const [message, { setError }] = useMessage();

  const handleForgotPassword = handleSubmit(async ({
    username = '',
  }) => {
    try {
      await Auth.forgotPassword(username);
      history.push('/reset-password', {
        username,
      });
    } catch (e: any) {
      if (typeof e.message === 'string') {
        setError(e.message as string);
      }
    }
  });

  return (
    <form onSubmit={handleForgotPassword}>
      <Box mb={4}>
        <Typography variant="subtitle1" color="primary.main">
          {t`Reset your password`}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} mb={2}>
        {message && (
          <Alert severity={message.type}>
            {message.text}
          </Alert>
        )}
        <TextField
          type="text"
          required
          label={t`Username`}
          placeholder={t`Enter your username`}
          defaultValue={location.state.username}
          {...register('username')}
        />
      </Stack>
      <Box mb={4}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t`Send code`}
        </Button>
      </Box>
      <BackToSignIn />
    </form>
  );
};

export default ForgottenPassword;
