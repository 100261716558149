import {
  Alert,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { t } from 'ttag';

import { useMessage } from '../useMessage';
import BackToSignIn from '../widgets/BackToSignIn';

interface ConfirmSignUpForm {
  code?: string;
}

export type ConfirmSignUpProps = RouteComponentProps<{}, {}, {
  username: string;
}>;

const ConfirmSignUp: React.FC<ConfirmSignUpProps> = ({ location }) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm<ConfirmSignUpForm>();
  const [message, { setError }] = useMessage();

  const handleConfirmSignUp = handleSubmit(async ({ code = '' }) => {
    try {
      await Auth.confirmSignUp(location.state.username, code);
      history.push('/');
    } catch (e: any) {
      if (typeof e.message === 'string') {
        setError(e.message as string);
      }
    }
  });

  return (
    <form onSubmit={handleConfirmSignUp}>
      <Box mb={4}>
        <Typography variant="subtitle1" color="primary.main">
          {t`Confirm sign up`}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} mb={2}>
        {message && (
          <Alert severity={message.type}>
            {message.text}
          </Alert>
        )}
        <Typography variant="body2">
          {t`Please check your emails for your confirmation code`}
        </Typography>
        <TextField
          disabled
          type="text"
          label={t`Username`}
          value={location.state.username}
        />
        <TextField
          type="text"
          label={t`Confirmation code`}
          placeholder={t`Enter your code`}
          {...register('code')}
        />
      </Stack>
      <Box mb={4}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t`Confirm`}
        </Button>
      </Box>
      <BackToSignIn />
    </form>
  );
};

export default ConfirmSignUp;
