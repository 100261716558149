import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { t } from 'ttag';

const BackToSignIn = () => (
  <Typography variant="subtitle2">
    <Link component={RouterLink} to="/">
      &laquo;
      {' '}
      {t`Back to Sign In`}
    </Link>
  </Typography>
);

export default BackToSignIn;
