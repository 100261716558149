import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import { Auth } from 'aws-amplify';

import { CurrentAuthenticatedUserReturnValue } from '../types/3rdParty/cognitoUser';
import { AppConfig } from '../types/config';
import {
  getConnectionFieldMergePolicy,
  imageDownloadUrlFieldMergePolicy,
} from './apolloCache';

export default async function prepareApolloClient(config: AppConfig) {
  const link = new HttpLink({
    uri: config.graphqlUri,
    fetch: async (input: RequestInfo, init?: RequestInit) => {
      const user: CurrentAuthenticatedUserReturnValue = await Auth.currentAuthenticatedUser();

      const jwt = user.signInUserSession.idToken.jwtToken;

      return fetch(input, {
        ...init,
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${jwt}`,
        },
      });
    },
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          Activities: getConnectionFieldMergePolicy(),
          Organisations: getConnectionFieldMergePolicy(),
          Users: getConnectionFieldMergePolicy(),
          listUserActivities: getConnectionFieldMergePolicy(['organisation']),
          listTeamActivities: getConnectionFieldMergePolicy(['organisation']),
          listOrgActivities: getConnectionFieldMergePolicy(['organisation']),
        },
      },
      Answer: {
        fields: {
          membersConnection: getConnectionFieldMergePolicy(),
        },
      },
      Manual: {
        fields: {
          contentConnection: getConnectionFieldMergePolicy(),
          membersConnection: getConnectionFieldMergePolicy(),
        },
      },
      Comment: {
        fields: {
          replyConnection: getConnectionFieldMergePolicy(),
        },
      },
      Topic: {
        fields: {
          commentConnection: getConnectionFieldMergePolicy(),
        },
      },
      Organisation: {
        fields: {
          answerConnection: getConnectionFieldMergePolicy(),
          manualConnection: getConnectionFieldMergePolicy(),
          membersConnection: getConnectionFieldMergePolicy(),
          teamsConnection: getConnectionFieldMergePolicy(),
          kweryConnection: getConnectionFieldMergePolicy(),
        },
      },
      MediaFile: {
        fields: {
          downloadUrl: imageDownloadUrlFieldMergePolicy(),
        },
      },
      Team: {
        fields: {
          membersConnection: getConnectionFieldMergePolicy(),
        },
      },
      User: {
        fields: {
          memberOfOrganisationConnection: getConnectionFieldMergePolicy(),
          memberOfTeamConnection: getConnectionFieldMergePolicy(),
        },
      },
      Vocabulary: {
        fields: {
          tagConnection: getConnectionFieldMergePolicy(),
        },
      },
    },
  });

  const persistor = new CachePersistor({
    cache,
    storage: window.localStorage,
  });

  await persistor.restore();

  // const schemaVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

  // if (schemaVersion === SCHEMA_VERSION) {
  //   await persistor.restore();
  // } else {
  //   await persistor.purge();
  //   window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  // }

  return new ApolloClient({
    cache,
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}
