import {
  Alert,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { t } from 'ttag';

import { useMessage } from '../useMessage';
import BackToSignIn from '../widgets/BackToSignIn';

interface SignUpForm {
  username?: string;
  password?: string;
  email?: string;
  phone?: string;
}

const SignUp = () => {
  const history = useHistory();
  const { register, handleSubmit } = useForm<SignUpForm>();
  const [message, { setError }] = useMessage();

  const handleSignUp = handleSubmit(async ({
    username = '',
    password = '',
    email = '',
    phone = '',
  }) => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number: phone,
        },
      });
      history.push('/confirm-sign-up', { username });
    } catch (e: any) {
      if (typeof e.message === 'string') {
        setError(e.message);
      }
    }
  });

  return (
    <form onSubmit={handleSignUp}>
      <Box mb={4}>
        <Typography variant="subtitle1" color="primary.main">
          {t`Create a new account`}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} mb={2}>
        {message && (
          <Alert severity={message.type}>
            {message.text}
          </Alert>
        )}
        <TextField
          type="text"
          label={t`Username`}
          {...register('username')}
        />
        <TextField
          type="password"
          label={t`Password`}
          {...register('password')}
        />
        <TextField
          type="email"
          label={t`Email address`}
          placeholder={t`Email`}
          {...register('email')}
        />
        <TextField
          type="phone"
          label={t`Phone number`}
          {...register('phone')}
        />
      </Stack>
      <Box mb={4}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t`Create account`}
        </Button>
      </Box>
      <BackToSignIn />
    </form>
  );
};

export default SignUp;
