import {
  Alert,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { t } from 'ttag';

import { useMessage } from '../useMessage';
import BackToSignIn from '../widgets/BackToSignIn';

interface ResetPasswordForm {
  code?: string;
  newPassword?: string;
}

export type ResetPasswordProps = RouteComponentProps<{}, {}, {
  username: string;
}>;

const ResetPassword: React.FC<ResetPasswordProps> = ({ location }) => {
  const history = useHistory();
  const { register, handleSubmit } = useForm<ResetPasswordForm>();
  const [message, { setError }] = useMessage();

  const handleResetPassword = handleSubmit(async ({
    code = '',
    newPassword = '',
  }) => {
    try {
      await Auth.forgotPasswordSubmit(
        location.state.username,
        code,
        newPassword,
      );
      history.push('/');
    } catch (e: any) {
      if (typeof e.message === 'string') {
        setError(e.message as string);
      }
    }
  });

  return (
    <form onSubmit={handleResetPassword}>
      <input type="hidden" value={location.state.username} />
      <Box mb={4}>
        <Typography variant="subtitle1" color="primary.main">
          {t`Reset your password`}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} mb={2}>
        {message && (
          <Alert severity={message.type}>
            {message.text}
          </Alert>
        )}
        <TextField
          type="text"
          label={t`Verification code`}
          placeholder={t`Enter code`}
          {...register('code')}
        />
        <TextField
          type="password"
          label={t`New password`}
          placeholder={t`Enter your new password`}
          {...register('newPassword')}
        />
      </Stack>
      <Box mb={4}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t`Submit`}
        </Button>
      </Box>
      <BackToSignIn />
    </form>
  );
};

export default ResetPassword;
