import {
  createTheme as createThemeV4,
  responsiveFontSizes as responsiveFontSizesV4,
} from '@material-ui/core';
import { createTheme, responsiveFontSizes } from '@mui/material';

import { PRIMARY_GRADIENT } from './variables';

export const light = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#113c6e',
      },
      secondary: {
        main: '#d2a538',
      },
    },
    typography: {
      allVariants: {
        fontFamily: '"Libre Franklin", sans-serif',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundImage: PRIMARY_GRADIENT,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          primary: {
            backgroundImage: PRIMARY_GRADIENT,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorPrimary: {
            backgroundImage: PRIMARY_GRADIENT,
          },
          outlinedPrimary: {
            backgroundImage: 'none',
          },
        },
      },
    },
  }),
);

// TODO: Remove MUI v4
// https://github.com/invotra/kommand/issues/50
export const themeV4 = responsiveFontSizesV4(
  createThemeV4({
    palette: {
      primary: {
        main: '#113c6e',
      },
      secondary: {
        main: '#d2a538',
      },
    },
    typography: {
      allVariants: {
        fontFamily: '"Libre Franklin", sans-serif',
      },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundImage: PRIMARY_GRADIENT,
        },
      },
      MuiFab: {
        primary: {
          backgroundImage: PRIMARY_GRADIENT,
        },
      },
      MuiChip: {
        colorPrimary: {
          backgroundImage: PRIMARY_GRADIENT,
        },
        outlinedPrimary: {
          backgroundImage: 'none',
        },
      },
    },
  }),
);
