import { AlertColor } from '@mui/material';
import { useCallback, useState } from 'react';

export interface Message {
  text: string;
  type: AlertColor;
}

export type MessageState = Message | null;

export const useMessage = (): [
  Message | null,
  {
    clear: () => void,
    setSuccess: (message: string) => void,
    setInfo: (message: string) => void,
    setWarning: (message: string) => void,
    setError: (message: string) => void,
    setMessage: React.Dispatch<React.SetStateAction<Message | null>>,
  },
] => {
  const [message, setMessage] = useState<Message | null>(null);

  return [
    message,
    {
      clear: useCallback(() => setMessage(null), [setMessage]),
      setSuccess: useCallback((text) => setMessage({
        text,
        type: 'success',
      }), [setMessage]),
      setInfo: useCallback((text) => setMessage({
        text,
        type: 'info',
      }), [setMessage]),
      setWarning: useCallback((text) => setMessage({
        text,
        type: 'warning',
      }), [setMessage]),
      setError: useCallback((text) => setMessage({
        text,
        type: 'error',
      }), [setMessage]),
      setMessage,
    },
  ];
};
