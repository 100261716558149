import { ThemeProvider as ThemeProviderV4 } from '@material-ui/core';
import { ThemeProvider } from '@mui/material';
import Amplify, { Auth as AmplifyAuth } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'ttag';

import Loading from './components/screens/Loading';
import ApolloClientContext from './contexts/ApolloClient';
import loadAppConfig from './lib/loadAppConfig';
import prepareApolloClient from './lib/prepareApolloClient';
import reportWebVitals from './reportWebVitals';
import { light, themeV4 } from './styles/theme';
import Auth from './components/Auth';

const root = document.getElementById('root');

(async () => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProviderV4 theme={themeV4}>
        <Loading />
      </ThemeProviderV4>
    </React.StrictMode>,
    root,
  );

  const config = await loadAppConfig();

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.region,
      userPoolId: config.userPool,
      userPoolWebClientId: config.clientId,
    },
  });

  try {
    await AmplifyAuth.currentAuthenticatedUser();
    const client = await prepareApolloClient(config);

    const { default: App } = await import('./components/App');

    ReactDOM.render(
      <React.StrictMode>
        <ApolloClientContext.Provider value={client}>
          <ThemeProviderV4 theme={themeV4}>
            <App />
          </ThemeProviderV4>
        </ApolloClientContext.Provider>
      </React.StrictMode>,
      root,
    );
  } catch (e) {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={light}>
          <Auth title={t`Welcome to Klarity Kommand Centre`} />
        </ThemeProvider>
      </React.StrictMode>,
      root,
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
