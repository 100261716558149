import { AppConfig } from '../types/config';

let appConfig: AppConfig;

export default async function loadAppConfig() {
  if (appConfig === undefined) {
    const configUrl = process.env.REACT_APP_CONFIG_PATH
      || '/tenant/app-config.json';

    const appConfigRequest = await fetch(configUrl);
    appConfig = await appConfigRequest.json();
  }

  return appConfig;
}
