import {
  Alert,
  Button,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { jt, t } from 'ttag';

import { useMessage } from '../useMessage';

interface SignInForm {
  username?: string;
  password?: string;
}

const SignIn = () => {
  const { register, handleSubmit, watch } = useForm<SignInForm>();
  const [message, { setError }] = useMessage();

  const handleSignIn = handleSubmit(async ({ username = '', password = '' }) => {
    try {
      await Auth.signIn(username, password);
      window.location.reload();
    } catch (e: any) {
      if (typeof e.message === 'string') {
        setError(e.message as string);
      }
    }
  });

  const forgottenPasswordLink = (
    <Link
      component={RouterLink}
      to={{
        pathname: '/forgotten-password',
        state: { username: watch('username') },
      }}
    >
      {t`Reset password`}
    </Link>
  );

  return (
    <form onSubmit={handleSignIn}>
      <Box mb={4}>
        <Typography variant="subtitle1" color="primary.main">
          {t`Sign in to your account`}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} mb={2}>
        {message && (
          <Alert severity={message.type}>
            {message.text}
          </Alert>
        )}
        <TextField
          type="text"
          label={t`Username`}
          placeholder={t`Enter your username`}
          {...register('username')}
        />
        <TextField
          type="password"
          label={t`Password`}
          placeholder={t`Enter your password`}
          {...register('password')}
        />
      </Stack>
      <Grid container direction="row-reverse" spacing={2} mb={4}>
        <Grid item xs>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {t`Sign in`}
          </Button>
        </Grid>
        <Grid item xs>
          <Button fullWidth component={RouterLink} to="/sign-up">
            {t`Create account`}
          </Button>
        </Grid>
      </Grid>
      <Typography variant="subtitle2">
        {jt`Forgotten password? ${forgottenPasswordLink}`}
      </Typography>
    </form>
  );
};

export default SignIn;
