import React, { useContext } from 'react';

export default function useRequiredContext<T>(
  context: React.Context<T | null>,
) {
  const contextValue = useContext(context);
  if (!contextValue) {
    throw new Error(`The ${context.displayName} value is required`);
  }

  return contextValue;
}
