import {
  Container,
  CssBaseline,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import { t } from 'ttag';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import ConfirmSignUp from './screens/ConfirmSignUp';
import ForgottenPassword from './screens/ForgottenPassword';
import ResetPassword from './screens/ResetPassword';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';

export interface AuthProps {
  title?: string;
}

const Auth: React.FC<AuthProps> = ({
  title = t`Welcome to Klarity Works`,
}) => (
  <MemoryRouter>
    <Container maxWidth="sm">
      <CssBaseline />
      <Stack justifyContent="center" height="100vh">
        <Paper variant="outlined">
          <Box px={12} py={4}>
            <Box textAlign="center" mb={2}>
              <Logo width={120} height={120} />
            </Box>
            <Typography variant="h6" color="primary.main">
              {title}
            </Typography>
            <Switch>
              <Route exact path="/" component={SignIn} />
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/confirm-sign-up" component={ConfirmSignUp} />
              <Route exact path="/forgotten-password" component={ForgottenPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
            </Switch>
          </Box>
        </Paper>
      </Stack>
    </Container>
  </MemoryRouter>
);

export default Auth;
